import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

import ScrollToTop from '../components/scrollToTop';
import RequestForm from '../components/RequestForm';

export default function Homenew(props) {
  const { translations } = props.pageContext;
  const [showNav, setShowNav] = useState(false);
  const { language } = useSelector((state) => state);
  const [staticText, setText] = useState(null);

  useEffect(() => {
    if (translations) {
      setText(translations[language]);
    }
  }, [translations]);

  useEffect(() => {
    setText(translations[language]);
  }, [language]);

  return (
    <>
      {staticText && (
        <div id="page-wrap" className="m-0 p-0">
          <section id="dashboard-banner">
            <div className="dash-banner-container dsp-flx flx-center">
              <div className="dashboard-inner dsp-flx">
                <div className="dashboard-top-banner">
                  <h1 className="banner-text-pre-header fnt-primary fnt-r-22 bold fnt-evolventa mb-3">
                    {staticText.top.titleFree}
                  </h1>
                  <h2 className="banner-text-header fnt-evolventa mb-2">{staticText.top['lg_title']}</h2>
                  <h2 className="banner-text-sub fnt-jomolhari fnt-white mb-4">{staticText.top['md_title']}</h2>
                  <p className="banner-text-description fnt-poppins">{staticText.top['description']}</p>
                  <div className="pt-4 hide-mobile">
                    <a
                      href="#request-more-section-free-book"
                      className="bg-prime fnt-white fnt-evolventa bold pl-3 pr-3 pt-2 pb-2 border-none"
                    >
                      {staticText.top['btnfree']}
                    </a>
                  </div>
                  <div className="pt-4 hide-desktop">
                    <a
                      href="#request-more-section-free-book"
                      className="bg-prime fnt-white fnt-evolventa bold pl-3 pr-3 pt-2 pb-2 border-none"
                    >
                      {staticText.top['btnfree']}
                    </a>
                  </div>
                </div>
                <div className="dashboard-banner-image flx-1">
                  <img
                    src="https://mk0childrensceno0ux5.kinstacdn.com/wp-content/uploads/2018/10/ccc_set.png"
                    className="attachment-full size-full"
                    alt=""
                    loading="lazy"
                    srcSet="https://mk0childrensceno0ux5.kinstacdn.com/wp-content/uploads/2018/10/ccc_set.png 600w, https://mk0childrensceno0ux5.kinstacdn.com/wp-content/uploads/2018/10/ccc_set-279x300.png 279w"
                    sizes="(max-width: 600px) 100vw, 600px"
                  />
                  <div className="position-absolute dash-bg-container">
                    <img
                      src="https://mk0childrensceno0ux5.kinstacdn.com/wp-content/uploads/2019/11/Book-bg.svg"
                      alt=""
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="mt-5 hide-desktop">
                  {/* need to work here */}
                  <a className="bg-prime fnt-white fnt-evolventa bold pl-3 pr-3 pt-2 pb-2 border-none">
                    {staticText.top['btn']}
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section className="dsp-flx flx-center">
            <div className="video-container">
              <div className="video-inner">
                <div className="dsp-flx mb-3 mt-2">
                  <h2 className="fnt-primary fnt-evolventa bold fnt-r-22 m-0">{staticText['section_video'].title}</h2>
                </div>
                <div className="pr-5">
                  <h3 className="fnt-jomolhari fnt-r-38 video-text-sub">{staticText['section_video'].lg_title}</h3>
                </div>
                <div className="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <div
                    className="wistia_responsive_wrapper"
                    style={{
                      height: '100%',
                      left: '0',
                      position: 'absolute',
                      top: '0',
                      width: '100%',
                    }}
                  >
                    <div
                      className={`wistia_embed wistia_async_wikxd59i1h ${language !== 'eng' ? 'hidden-video' : ''}`}
                      style={{ width: '100%', height: '100%' }}
                    >
                      &nbsp;
                    </div>

                    <div
                      className={`wistia_embed wistia_async_9s3afye8n5 ${language !== 'esp' ? 'hidden-video' : ''}`}
                      style={{ width: '100%', height: '100%' }}
                    >
                      &nbsp;
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white request-more-inner" id="request-more-section-free-book">
                <div className="mb-4">
                  <h2 className="fnt-evolventa bold fnt-r-26 m-0">
                    <span className="fnt-primary">{staticText['section_form'].titleFreeBook}</span>
                    <br />
                    <span className="fnt-black">{staticText['section_form'].sub_titlefreeBook}</span>
                  </h2>
                </div>
                <RequestForm from="freeBook" staticText={staticText['section_form']} language={language} />
              </div>
            </div>
          </section>

          <ScrollToTop show={!showNav} />
        </div>
      )}
    </>
  );
}
